site.window = {
	scrollPosition: 0
};

site.magnific = {
	// Webcam image source
	webcamSrc: $('a.camera', "#qn").attr('href'),
	webcamReloadInterval: null,

	init: function () {
		// Initialize extras item gallery
		$('.magnific-item').magnificPopup({
			type: 'image',
			gallery: {
				enabled: true
			}
		});

		// Initialize live webcam
		$('.camera', '#qn').magnificPopup({
			src: site.magnific.webcamSrc,
			type: 'image',
			callbacks: {
				open: function() {
					// Reload image every 10 seconds
					site.magnific.webcamReloadInterval = setInterval(function() {
						$('.mfp-img[src*="' + site.magnific.webcamSrc + '"]').attr('src', site.magnific.webcamSrc + '?rand=' + Math.random());
					}, 10000);
				},
				close: function() {
					clearInterval(site.magnific.webcamReloadInterval);
				}
			}
		});
	}
};

site.inits.push('site.magnific.init()');
