site.window = {
	scrollPosition: 0
};

site.nav = {
	// Init navigation scripts
	init: function() {
		// Add expand link to mobile menu
		$('.children', '#mobilemenu ').prepend('<span class="expand" href="#"><div class="first"></div><div class="second"></div></span>');
		$('.selectedLevel1','#mobilemenu').addClass('down');
		$('.selectedLevel2','#mobilemenu').addClass('down');
		site.nav.bind();
		site.nav.appendQn();
	},
	bind: function() {
		// Mobile menu toggle
		$('#mainmenuToggle').click(function(e) {
			$('body').toggleClass('mainmenu-open');
			$('body.mainmenu-open').on('click', function (e) {
				$('body').removeClass('mainmenu-open');
			});
			e.stopPropagation();
			e.preventDefault();
		});

		// Close sidebar on esc press
		$(document).keydown(function(e) {
			if (e.keyCode == 27) {
				$('body').removeClass('mainmenu-open');
			}
		});

		$('#mainmenu').on('click', function(e) {
			e.stopPropagation();
		});

		// Expand in mobile menu
		$('.expand', '#mobilemenu').bind('click', function(e) {
			e.preventDefault();
			$(this).closest('li').toggleClass('down');
		});
	},
	// Append language to site
	appendLanguage: function() {
		var $divisions = $('.menu', '#divisions');
		var $menu = $('ul.mmLevel1', '#mMenu');

		var $html = $('<li class="ele-language si level1"></li>');
		$divisions.find('li').each(function() {
			var $li = $(this).clone();
			$html.append($li.find('a'));
		});

		$menu.append($html);
	},
	appendQn: function() {
		var $top = $('<div class="hidden-md hidden-lg top-buttons"></div>'),
		    $menu = $('.mmLevel1', '#mainmenu'),
			$qn = $('ul:first-child li', '#qn').clone().addClass('level1 hidden-lg hidden-md'),
			$flags = $('ul:not(:first-child) li > *', '#qn').clone().appendTo($top);
		$menu.append($qn);
		$('.contentWrap', '#mainmenu').prepend($top);
	}
};

site.inits.push('site.nav.init()');
