var site = site || {};
site.inits = [];

site.window = {
	scrollPosition: 0
};

site.device = {
	// Check if device is a touch device
	isTouch: function () {
		try {
			document.createEvent("TouchEvent");
			return true;
		} catch (e) {
			return false;
		}
	}
};


// A few layout methods
site.layout = {
	// Wrap elements into a div.row
	rowWrap: function (selectorBegin, selectorEnd, extraClasses) {
		if (selectorEnd == undefined) {
			selectorEnd = selectorBegin;
		}

		if (extraClasses == undefined) {
			extraClasses = '';
		}

		$(selectorBegin).nextUntil(selectorEnd).andSelf().add(selectorEnd)
			.wrapAll("<div class='row " + extraClasses + "'></div>");
	}
}


site.owl = {
	// Shorthand to init owlcarousel
	init: function (id, options) {
		var $owl = $(id);
		if ($owl.length > 0) {
			$owl.addClass('owl-carousel'); // This is to fix a change made in owl 2.0.0-beta.3
			$owl.owlCarousel(options);
		}
	}
};

// Run all the inits \o/
$(function () {
	$.each(site.inits, function (i, value) {
		var fun = new Function(value);
		fun();
	});

	//Responsive youtube and vimeo iframe
	$('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]', '#main').each(function () {
		$(this).attr('allowfullscreen', '').wrap('<div class="video" style="max-width:' + $(this).width() + 'px"><div class="embed-responsive embed-responsive-16by9"></div></div>');
	});

	// Responsive tables
	if (!$('body').is('.admin')) {
		$('table', '#main').wrap('<div class="table-responsive"></div>');
	}

	// Touch login
	$('.login').dblclick(function (e) {
		e.preventDefault();
		window.location = '/moya';
	});

	// Make all of the box in box4 clickable
	$('.info', '#promo').click(function (e) {
		window.location = $(this).find("h3 a").attr("href");
		return false;
	});

	// Make all of the box in box4 clickable
	$('.items .info', "#extras").click(function (e) {
		window.location = $(this).find(".title a").attr("href");
		return false;
	});

	$('.description h4 + ul', '#extras').each(function() {
		$(this).prev('h4').andSelf().wrapAll('<div class="info-list-wrap">');
	});

	// Reorder language menu based on selected
	// Move is to the top
	//$li = $('.selected', '#divisions');
	//$ul = $('.menu', '#divisions');
	//$ul.prepend($li);
});
