site.hero = {
	owl: $('#owl-hero'),

	init: function() {
		//if (!site.hero.length) return;

		site.hero.owl.addClass('owl-carousel'); // This is to fix a change made in owl 2.0.0-beta.3
		site.hero.owl.owlCarousel({
			loop:true,
			navigation:false,
			navText:["",""],
			dots: true,
			stopOnHover: true,
			autoplay: false,
			animateOut: 'fadeOut',
			animateIn: 'fadeIn',
			autoplayTimeout: 4500,
			autoplayHoverPause: true,
			items: 1
		});

		site.hero.bindDown();
	},

	bindDown: function() {
		site.hero.owl.find('.down').click(function(e) {
			e.preventDefault();
			if ($(window).width() <= 991) {
				$('html, body').animate({scrollTop:site.hero.owl.offset().top + site.hero.owl.height() - $('#header').height()}, 'slow');
			} else {
				$('html, body').animate({scrollTop:site.hero.owl.offset().top + site.hero.owl.height()}, 'slow');
			}
		});
	}
};

site.inits.push('site.hero.init()');
